import React from 'react';

import { ReactComponent as Logo } from '../../assets/siberian-husky.svg';

import pdf from '../../assets/Resume.pdf';

import {
  HeaderContainer,
  LogoContainer,
  OptionsContainer,
  OptionLink,
  OptionPDF
} from './NavBar.styles';

export const Header = () => (
  <HeaderContainer>
    <LogoContainer to='/'>
      <Logo className='logo' />
    </LogoContainer>
    <OptionsContainer>
      <OptionLink to='/contact'>CONTACT</OptionLink>
      <OptionLink to='/portfolio'>PORTFOLIO</OptionLink>
      <OptionPDF href={pdf} target='_blank' rel="noopener noreferrer">RESUME</OptionPDF>
    </OptionsContainer>
  </HeaderContainer>
);

export default Header;

import React from 'react';

import {
  MenuItemContainer,
  BackgroundImageContainer,
  ContentContainer,
  ContentTitle,
  ContentSubtitle,
  HomePageContainer,
  DirectoryMenuContainer
} from './portfolio.styles.js';

import photographyimage from '../../assets/photography.jpeg';
import laptop from '../../assets/laptop.jpg';
import abstract from '../../assets/abstract-background.jpg';

const Portfolio = (props) => {
  return (
    <HomePageContainer>
      <DirectoryMenuContainer>
        <MenuItemContainer
          href='https://clthg-store-live.herokuapp.com/' target='_blank' rel="noopener noreferrer"
        >
          <BackgroundImageContainer
            className='background-image'
            imageUrl='https://image.freepik.com/free-photo/hand-holding-shopping-bags-plain-background_23-2148286215.jpg'
          />
          <ContentContainer className='content'>
            <ContentTitle>CRWN CLOTHING</ContentTitle>
            <ContentSubtitle>LIVE DEMO</ContentSubtitle>
          </ContentContainer>
        </MenuItemContainer>

        <MenuItemContainer
          href='https://github.com/cupofjoy/Quiet-Ambience' target='_blank' rel="noopener noreferrer"
        >
          <BackgroundImageContainer
            className='background-image'
            imageUrl={photographyimage}
          />
          <ContentContainer className='content'>
            <ContentTitle>QUIET AMBIENCE</ContentTitle>
            <ContentSubtitle>GITHUB LINK</ContentSubtitle>
          </ContentContainer>
        </MenuItemContainer>

        <MenuItemContainer
          href='https://github.com/cupofjoy/devconnector' target='_blank' rel="noopener noreferrer"
        >
          <BackgroundImageContainer
            className='background-image'
            imageUrl={laptop}
          />
          <ContentContainer className='content'>
            <ContentTitle>DEV CONNECTOR</ContentTitle>
            <ContentSubtitle>GITHUB LINK</ContentSubtitle>
          </ContentContainer>
        </MenuItemContainer>

        <MenuItemContainer
          href='https://github.com/cupofjoy/' target='_blank' rel="noopener noreferrer"
        >
          <BackgroundImageContainer
            className='background-image'
            imageUrl={abstract}
          />
          <ContentContainer className='content'>
            <ContentTitle>MORE @</ContentTitle>
            <ContentSubtitle>GITHUB</ContentSubtitle>
          </ContentContainer>
        </MenuItemContainer>

      </DirectoryMenuContainer>
    </HomePageContainer>
  )
};

export default Portfolio

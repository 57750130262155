import React from 'react';
// import logo from './logo.svg';
import './App.css';
import { Switch, Route } from 'react-router-dom';

import { GlobalStyle } from './global.styles';

import HomePage from './components/home-page/home-page.component';
import Header from './components/NavBar/NavBar.component';
import Contact from './components/contact/contact.component';
import Portfolio from './components/portfolio/portfolio.component';

function App() {
  return (
    <div>
      <GlobalStyle />
      <Header />
      <Switch>
        <Route exact path='/' component={HomePage} />
        <Route exact path='/contact' component={Contact} />
        <Route exact path='/portfolio' component={Portfolio} />
      </Switch>
    </div>
  );
}

export default App;

import React from 'react'

import { List, ListPoints, MenuItemContainer, BackgroundImageContainer, ContentContainer, Header } from './home-page.styles.js'

import wallpaper from '../../assets/japanese.jpg'

const HomePage = () => (
  <MenuItemContainer>
    <BackgroundImageContainer
      className='background-image'
      imageUrl={wallpaper}
    />
    <ContentContainer>
      <Header>EELAN TUNG</Header>
      <List>
        <ListPoints>FULL STACK WEB DEVELOPER</ListPoints>
        <ListPoints>TRAVEL AND PHOTOGRAPHY ENTHUSIAST</ListPoints>
        <ListPoints>COFFEE LOVER</ListPoints>
        <ListPoints>PROBLEM SOLVER</ListPoints>
      </List>
    </ContentContainer>
  </MenuItemContainer>
);

export default HomePage;

import React from 'react';

import {
  MenuItemContainer,
  BackgroundImageContainer,
  ContentContainer,
  ContentTitle,
  ContentSubtitle,
  DirectoryMenuContainer
} from './contact.styles';

import abstract from '../../assets/abstract.jpg'
import twitter from '../../assets/Twitter.jpg'

const Contact = (props) => {
  return (
    <DirectoryMenuContainer>
      <MenuItemContainer
        href='https://linkedin.com/in/eelantung' target='_blank' rel="noopener noreferrer"
      >
        <BackgroundImageContainer
          className='background-image'
          imageUrl={abstract}
        />
        <ContentContainer className='content'>
          <ContentTitle>LINKEDIN</ContentTitle>
          <ContentSubtitle>PROFILE</ContentSubtitle>
        </ContentContainer>
      </MenuItemContainer>

      <MenuItemContainer
        href='www.twitter.com/_eelan' target='_blank' rel="noopener noreferrer"
      >
        <BackgroundImageContainer
          className='background-image'
          imageUrl={twitter}
        />
        <ContentContainer className='content'>
          <ContentTitle>TWITTER</ContentTitle>
          <ContentSubtitle>CONTACT ME</ContentSubtitle>
        </ContentContainer>
      </MenuItemContainer>

      <MenuItemContainer
        href='mailto:eelantung@gmail.com?subject = Email&body = Message' target='_blank' rel="noopener noreferrer"
      >
        <BackgroundImageContainer
          className='background-image'
          imageUrl={abstract}
        />
        <ContentContainer className='content'>
          <ContentTitle>EMAIL</ContentTitle>
          <ContentSubtitle>CONTACT ME</ContentSubtitle>
        </ContentContainer>
      </MenuItemContainer>
    </DirectoryMenuContainer>
  )
}

export default Contact;
